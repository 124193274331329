import { publicRoutes } from "@src/routes";
import classnames from "classnames";
import * as React from "react";
import { LinkCC } from "@src/shared_modules/router";
import { SailpointLogo } from "./SailpointLogo";

// // // //
// Top-level bullet points for <AuthCard>

interface AuthBulletsProps {
    bulletPoints: string[];
}

export function AuthBullets(props: AuthBulletsProps) {
    const { bulletPoints } = props;
    return (
        <ul className="tw-flex tw-items-center tw-justify-center font-secondary-bold tw-text-center line-height-26-px tw-mb-4">
            {bulletPoints.map((b, index) => (
                <React.Fragment key={b}>
                    <li className="tw-flex tw-text-white font-size-16-px font-secondary-bold line-height-26-px">
                        {b}
                    </li>
                    {/* Don't render bullet-point circle for last bulletPoint */}
                    {index < bulletPoints.length - 1 && (
                        <i
                            style={{ fontSize: "0.5rem" }}
                            className="tw-flex fa fa-circle tw-my-0 tw-mx-8 tw-text-white"
                        />
                    )}
                </React.Fragment>
            ))}
        </ul>
    );
}

// // // //
// Title components for <AuthCard>

interface AuthCardTitleProps {
    title: string;
}

export function AuthCardTitle(props: AuthCardTitleProps) {
    return (
        <p className="font-primary-bold font-size-28-px text-updated-black pb-25-px text-center">
            {props.title}
        </p>
    );
}

// // // //
// CTA component for <AuthCard>

interface AuthCardCTAProps {
    prompt: string;
    linkText: string;
    linkHref: string;
}

export function AuthCardCTA(props: AuthCardCTAProps) {
    return (
        <p className="font-secondary tw-text-center font-size-14-px line-height-26-px">
            {props.prompt}
            <span className="tw-text-decoration-line font-secondary-bold tw-ml-2">
                <LinkCC href={`${props.linkHref}`}>
                    {props.linkText}
                    <i className="fa fa-fw fa-angle-double-right tw-ml-1" />
                </LinkCC>
            </span>
        </p>
    );
}

// // // //
// Helper component for rendering multiple child nodes inside an <AuthCard>
interface AuthCardBodyProps {
    children: React.ReactNode;
    className?: string;
}

export function AuthCardBody(props: AuthCardBodyProps) {
    const { className = "px-50-px py-50-px" } = props;
    return (
        <div className={classnames(className, "tw-max-w-100 tw-w-[500px]")}>
            {props.children}
        </div>
    );
}

// // // //
// Footer component for <AuthCard>

export interface AuthCardCompanyLogo {
    alt: string;
    src: string;
}

// // // //
// <AuthCard> Component Definition

interface AuthCardProps {
    children: React.ReactNode;
}

export interface AuthCardContentProps {
    bulletPoints: string[];
}

export function AuthCard(props: AuthCardProps & AuthCardContentProps) {
    return (
        <div className="py-100-px tw-bg-navy tw-h-screen">
            <div className="tw-flex pb-10-px tw-justify-center">
                <LinkCC href={publicRoutes.home}>
                    <SailpointLogo />
                </LinkCC>
            </div>
            <div className="container">
                <AuthBullets bulletPoints={props.bulletPoints} />

                <div className="row tw-flex tw-justify-center">
                    <div className="col-sm-12 col-md-8 col-lg-6">
                        <div className="tw-flex tw-flex-col tw-items-center tw-shadow-md tw-rounded-2xl bg-background-main">
                            {/* Login & Register Components are rendered here */}
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
