import { publicRoutes } from "@src/routes";
import {
    AuthCard,
    AuthCardBody,
    AuthCardContentProps,
    AuthCardTitle,
} from "@src/modules/auth/components/auth_card";
import { GlobalErrorAlert } from "@src/shared_modules/global_error_handler";
import { Link } from "@src/shared_modules/link";
import * as React from "react";
import EmailLoginForm from "./EmailLoginForm";
import styles from "./styles.module.css";

// // // //

function LoginLinks() {
    return (
        <span className="d-flex justify-content-center mt-25-px">
            <Link
                className={`${styles.navlink} tw-underline mr-15-px`}
                href={publicRoutes.forgot_password}
            >
                Forgot your password?
            </Link>
            <Link
                className={`${styles.navlink} tw-underline ml-15-px`}
                href={publicRoutes.loginSSO}
            >
                Login via SSO
            </Link>
        </span>
    );
}

// // // //

export interface LoginPageProps {
    loginCardTitle: string;
    loginCtaText: string;
    loginCtaLinkText: string;
}

export function Login(props: LoginPageProps & AuthCardContentProps) {
    return (
        <AuthCard bulletPoints={props.bulletPoints}>
            <AuthCardBody>
                <AuthCardTitle title={props.loginCardTitle} />

                <GlobalErrorAlert className="mb-15-px" />

                <EmailLoginForm />

                <LoginLinks />
            </AuthCardBody>
        </AuthCard>
    );
}
