import { PageTrack } from "@src/shared_modules/page_track";
import Head from "next/head";
import * as React from "react";

interface LayoutProps {
    title: string;
    children: React.ReactNode;
    pageTrackParams?: { [key: string]: string | number };
}

export default function Layout(props: LayoutProps) {
    const { pageTrackParams } = props;
    return (
        <React.Fragment>
            <Head>
                <title>{`SailPoint SaaS Management - ${props.title}`}</title>
            </Head>

            <PageTrack trackParams={pageTrackParams}>
                {props.children}
            </PageTrack>
        </React.Fragment>
    );
}
