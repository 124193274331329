import { authClient } from "@src/requests/apollo-auth";
import { routes } from "@src/routes";
import { Toaster } from "@src/shared_modules/toaster";
import { ErrorResponse } from "apollo-link-error";
import * as React from "react";
import { Mutation } from "react-apollo";
import { loginUserMutation } from "./graphql";

// // // //

interface EmailLoginFormState {
    email: string;
    error?: string;
    loading: boolean;
    password: string;
}

interface EmailAuth {
    email: string;
    password: string;
}

// // // //

export default class EmailLoginForm extends React.Component<
    {},
    EmailLoginFormState
> {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            error: null,
            loading: false,
            password: "",
        };
    }

    public render() {
        return (
            <Mutation<boolean, EmailAuth>
                client={authClient}
                mutation={loginUserMutation}
            >
                {(commitMutation) => (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            this.onSubmit(commitMutation, {
                                email: this.state.email,
                                password: this.state.password,
                            });
                        }}
                    >
                        <label htmlFor="email" className="font-secondary-bold">
                            Work Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            placeholder="Your Work Email"
                            onChange={(e) =>
                                this.setState({
                                    email: e.currentTarget.value,
                                })
                            }
                            required
                        />

                        <br />

                        <label
                            htmlFor="password"
                            className="font-secondary-bold"
                        >
                            Password
                        </label>
                        <input
                            type="password"
                            name="password"
                            placeholder="Your Work Password"
                            onChange={(e) =>
                                this.setState({
                                    password: e.currentTarget.value,
                                })
                            }
                            required
                        />

                        <button
                            type="submit"
                            className="green-cta-round-button mt-15-px"
                            disabled={this.disableSubmit()}
                        >
                            {this.state.loading ? (
                                <i className="fa fa-spinner fa-spin" />
                            ) : (
                                <React.Fragment>Log in</React.Fragment>
                            )}
                        </button>

                        {this.state.error ? (
                            <Toaster
                                type="danger"
                                removable
                                duration={60}
                                text={this.state.error}
                                onRemove={() => {
                                    this.setState({ error: null });
                                }}
                            />
                        ) : null}
                    </form>
                )}
            </Mutation>
        );
    }

    private disableSubmit() {
        return !this.state.email || !this.state.password || this.state.loading;
    }

    private onSubmit(commitMutation: Function, input: EmailAuth) {
        this.setState({ loading: true });

        commitMutation({ variables: { input } })
            .then(() => {
                window.location.href = routes.overview;
            })
            .catch((error: ErrorResponse) => {
                // Display error Toaster
                let message: string = "";
                if (error.graphQLErrors) {
                    message = error.graphQLErrors
                        .map((e) => e.message)
                        .join("\n");
                } else if (error.networkError) {
                    message = "An unexpected network error occurred";
                }

                this.setState({
                    error: message,
                    loading: false,
                });
            });
    }
}
