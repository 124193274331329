import { CoreEvents } from "@src/analytics/events";
import { track } from "@src/analytics/track";
import * as React from "react";

// // // //

/**
 * PageTrack
 * Simple component that fires off a "coreEvents.pageLoad" event on the component mounting
 * NOTE - this is a small re-implementation of the PageTrack component from uncommons, modified to use our latest "track" function
 * which automatically includes the canonical page name and additional metadata
 */
export class PageTrack extends React.Component<
    {
        children: React.ReactNode;
        trackParams?: {
            [key: string]: string | number;
        };
    },
    {}
> {
    public componentDidMount() {
        const { trackParams = {} } = this.props;
        // Fire off "track" function when component mounts
        track(CoreEvents.pageLoad, trackParams);
    }

    public render() {
        return this.props.children;
    }
}
