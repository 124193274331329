import { DEFAULT_AUTH_PAGE_PROPS } from "@src/modules/auth/constants";
import { Login } from "@src/modules/auth/pages/login";
import Layout from "@src/shared_modules/layout";

// // // //

export default function LoginPage() {
    return (
        <Layout title="Login">
            <Login {...DEFAULT_AUTH_PAGE_PROPS} />
        </Layout>
    );
}
