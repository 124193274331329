import { LoginPageProps } from "@src/modules/auth/pages/login";
import { AuthCardContentProps } from "@src/modules/auth/components/auth_card";

interface RegisterPageProps {
    landingTitle: string;
    landingSubtitle: string;
    registerCardTitle: string;
    registerCtaText: string;
    registerCtaLinkText: string;
}

interface ResetPWContent {
    resetPasswordCardTitle: string;
    resetPasswordSuccessMessage: string;
}

interface ForgotPWContent {
    forgotPasswordSuccessMessage: string;
}

// Defines type to encapsulate all the AuthPage content
export interface AuthPageProps
    extends AuthCardContentProps,
        RegisterPageProps,
        ResetPWContent,
        ForgotPWContent,
        LoginPageProps {}

// Default object to return in case of failed contentful call
export const DEFAULT_AUTH_PAGE_PROPS: AuthPageProps = {
    landingTitle: "Get started improving your SaaS management",
    landingSubtitle: "Sign up and start your free 14-day trial",
    loginCardTitle: "Log in",
    loginCtaText: "Don't have an account?",
    loginCtaLinkText: "Create one",
    registerCardTitle: "Create your account",
    registerCtaText: "Already have an account?",
    registerCtaLinkText: "Log in here",
    bulletPoints: ["Manage Spend", "Optimize Usage", "Strengthen Security"],
    resetPasswordCardTitle: "Set your Password",
    resetPasswordSuccessMessage:
        "Your password is reset! Redirecting you to your dashboard",
    forgotPasswordSuccessMessage:
        "Success! If you have an account with this email we'll send you instructions to reset your password",
};
